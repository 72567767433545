.login-bg {
  background-image: url(https://images.unsplash.com/photo-1566164662067-7eeea66d35e9?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  position: absolute;
  border: 0;
  height: 100%;
  width: 50%;
  z-index: 0;
  left: 0;
  top: 0;
}