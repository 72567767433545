@import url('https://fonts.googleapis.com/css2?family=Anta&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 2 / 3 / 3 / 4;
}
.div5 {
  grid-area: 2 / 2 / 3 / 3;
}
.div6 {
  grid-area: 2 / 1 / 3 / 2;
}

.filters p.MuiTypography-root {
  display: none;
}

.grid .parent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  min-height: 724px;
}

.grid .div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.grid .div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.grid .div3 {
  grid-area: 2 / 1 / 3 / 2;
}
.grid .div4 {
  grid-area: 2 / 2 / 3 / 3;
}
.grid .div5 {
  grid-area: 3 / 1 / 5 / 3;
}
.grid .div6 {
  grid-area: 1 / 3 / 4 / 5;
}
.grid .div7 {
  grid-area: 4 / 3 / 5 / 5;
}
.grid .div8 {
  grid-area: 1 / 5 / 2 / 8;
}
.grid .div9 {
  grid-area: 2 / 5 / 3 / 8;
}
.grid .div10 {
  grid-area: 3 / 5 / 5 / 8;
}

.extend-cell {
  grid-area: 2 / 1 / 3 / 3 !important;
}

@media (max-width: 1024px) {
  .grid .parent {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  .grid .div1 {
    grid-area: 1 / 1 / 1 / 2;
  }
  .grid .div2 {
    grid-area: 1 / 2 / 1 / 2;
  }
  .grid .div3 {
    grid-area: 2 / 1 / 2 / 2;
  }
  .grid .div4 {
    grid-area: 2 / 2 / 2 / 3;
  }
  .grid .div5 {
    grid-area: 3 / 1 / 6 / 3;
  }
  .grid .div6 {
    grid-area: 6 / 1 / 7 / 3;
  }
  .grid .div7 {
    grid-area: 7 / 1 / 8 / 3;
  }
  .grid .div8 {
    grid-area: 8 / 1 / 9 / 3;
  }
  .grid .div9 {
    grid-area: 9 / 1 / 10 / 3;
  }
  .grid .div10 {
    grid-area: 10 / 1 / 11 / 3;
  }
}

@media (max-width: 768px) {
  .grid .parent {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  .grid .div1 {
    grid-area: 1 / 1 / 2 / 3;
    height: 120px;
  }
  .grid .div2 {
    grid-area: 2 / 1 / 3 / 3;
    height: 120px;
  }
  .grid .div3 {
    grid-area: 3 / 1 / 4 / 3;
    height: 120px;
  }
  .grid .div4 {
    grid-area: 4 / 1 / 5 / 3;
    height: 120px;
  }
  .grid .div5 {
    grid-area: 5 / 1 / 6 / 3;
  }
  .grid .div6 {
    grid-area: 6 / 1 / 7 / 3;
  }
  .grid .div7 {
    grid-area: 7 / 1 / 8 / 3;
  }
  .grid .div8 {
    grid-area: 8 / 1 / 9 / 3;
    height: 120px;
  }
  .grid .div9 {
    grid-area: 9 / 1 / 10 / 3;
    height: 120px;
  }
  .grid .div10 {
    grid-area: 10 / 1 / 11 / 3;
  }
}
